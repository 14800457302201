import { Button } from '@findigs/ui-kit/src/components/button';
import { useCallback } from 'react';
import styled from 'styled-components';
import type { UseRemoveDocumentModalState } from '../hooks/use-remove-document-modal';
import ConfirmModalLayout from '../layouts/confirm-modal-layout';
import type { ModalProps } from '../state/modal';
import RemoveIcon from '../images/modals/remove-document-icon.inline.svg';

export interface RemoveDocumentModalProps extends ModalProps {
  options: UseRemoveDocumentModalState['options'];
}

const StyledConfirmModalLayout = styled(ConfirmModalLayout)`
  /** 768px - tablet **/
  @media (min-width: 48rem) {
    max-width: 32.75rem;

    .cancel {
      flex-basis: 13.375rem;
    }
  }
`;

const RemoveDocumentModal = ({
  options: { onConfirm, description },
  hide,
}: RemoveDocumentModalProps) => {
  const _onConfirm = useCallback(() => {
    hide();
    if (onConfirm) {
      onConfirm();
    }
  }, [hide, onConfirm]);

  const Confirm = (
    <Button type="button" variant="FilledStandard" onClick={_onConfirm}>
      Yes, remove document
    </Button>
  );
  const Cancel = (
    <Button type="button" variant="Tonal" onClick={hide} className="cancel">
      Cancel
    </Button>
  );

  return (
    <StyledConfirmModalLayout
      onDismiss={hide}
      icon={<RemoveIcon aria-hidden={true} />}
      actions={[Confirm, Cancel]}
    >
      <h2 className="text-t-sm mb-8">Remove this document?</h2>
      <p className="text-b-md-regular text-muted">{description}</p>
    </StyledConfirmModalLayout>
  );
};

export default RemoveDocumentModal;
